.react-datepicker-wrapper {
  display: block;
}

.error.error-6 .error-title {
  font-size: 5rem !important;
}

@media (min-width: 768px) {
  .error.error-6 .error-title {
    font-size: 10rem !important;
  }
}