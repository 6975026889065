.react-datepicker-wrapper {
  display: block;
}

.error.error-3 .error-title {
  font-size: 8.7rem !important;
}

@media (min-width: 768px) {
  .error.error-3 .error-title {
    font-size: 15.7rem !important;
  }
}